.direction-form {
	margin: auto;
	justify-content: space-around;
	align-items: center;
}
.direction-select {
	width: 100%;
	margin-bottom: 20px;
}
.direction-select-row {
	width: 100%;
	justify-content: space-between;
	margin-bottom: 20px;
}
.center {
	display: flex;
	justify-content: center;
	align-items: center;
}
.center-btn {
	display: block !important;
	margin: 0 auto !important;
}
.form-title {
	text-align: center;
	margin-bottom: 20px;
	font-size: 2rem;
	font-weight: 400;
}
.pagination-buttons {
	width: 100%;
	justify-content: flex-end;
	display: flex;
	margin-top: 20px;
}
.maxWidth {
	width: 100%;
}
.department-field {
    display: flex;
}
.test {
    width: 45%;
    border: 1px solid red !important;
}
