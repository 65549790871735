.container-tree {
    height: auto;
    border: none;
}
.orgchart {
    background-image: none;
}
.orgchart > ul > li > ul li::before {
    border-top-color: #017794;
}
  
.orgchart > ul > li > ul li .oc-node::before,
.orgchart ul li .oc-node:not(:only-child)::after {
    background-color: #017794;
}
.oc-symbol {
    display: none;
}
.oc-heading {
    background-color:#017794 !important;
}
.oc-content {
    border: 1px solid #017794 !important;
}
.center-btns {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.hidden-category {
    display: none !important;
}