.form-filter {
	display: flex;
	width: 100%;
	justify-content: space-between;
	margin: 15px 0;
}
.add-filter-btn {
	margin-bottom: 15px !important;
}
.align-filter .ant-space-item,
.align-filter .ant-select {
	width: 200px;
}
.typify-tab .ant-tabs-nav {
	margin-bottom: 0 !important;
}
.typify-select-table {
	margin-right: 15px !important;
}
.create-typify-table-row {
	justify-content: space-evenly;
}
.tab-vertical {
	background-color: white;
	padding: 10px !important;
}
