.tag-list-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
.tag-container {
    display: flex;
}
.tag-center {
    margin: 0 auto !important;
    display: block !important;
    width: 200px;
}
.tag-name {
    width: 57% !important;
}
.tag-value {
    width: 43% !important;
}

@media (max-width: 500px) {
    .tag-list-container {
        grid-template-columns: 1fr;
    } 
}