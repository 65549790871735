.form-subtitle {
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 25px;
}
.total-payment {
    margin: 25px 0;
    text-align: center;
}
.center-spin {
    position: relative !important;
    left: 50% !important;
    transform: translateX(-50%);
}
.rut-width {
    min-width: 400px;
    width: 80%;
}