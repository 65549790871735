.PhoneInputInput {
	height: 32px;
	color: rgba(0, 0, 0, 0.85);
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: border 0.25s;
}
.PhoneInputInput::placeholder {
	color: #bfbfbf !important;
}
.PhoneInputInput:disabled {
	background: #f0f0f0 !important;
	cursor: not-allowed;
}
.PhoneInputInput:disabled:hover {
	border: 1px solid #d9d9d9;
}

.PhoneInputInput--focus {
	border: 0;
}

.PhoneInputInput:hover {
	border: 1px solid #03b2cb;
}

#clientPhoneFormList {
	margin-bottom: 1px;
}
.abs-pos-error {
	position: absolute;
	z-index: -1;
}
.phone-select-margin {
	margin-bottom: 25px;
}
.timeline-icon {
	font-size: 20px;
}
.table-pagination {
	width: 100%;
	justify-content: flex-end;
	display: flex;
	margin-top: 20px;
}
.client-phone-field {
	display: flex;
	justify-content: center;
}
