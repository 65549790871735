.ticket-container {
    display: flex;
    justify-content: space-between;
}
.ticket-flex {
    display: flex;
}
.ticket-flex-input {
    flex: 1;
}
.center-spinner {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
}
.chat-container {
    flex: 1;
    position: relative;
    max-height: 300px;
    overflow: auto;
}
.chat-msg {
    border: 1px solid lightgray;
    background-color: rgb(186, 243, 186);
    border-radius: 5px;
    padding: 5px 10px;
}
.step-container {
    flex: 1;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid lightgray;
}