.column-item-container {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}
.column-item {
    width: 25% !important;
    max-height: 32px;
    display: inline-block;
}
.column-item2 {
    width: 39% !important;
    max-height: 32px;
    display: inline-block;
}
.column-item .ant-btn {
    width: 100%;
}

.edit-column-drive {
    margin-top: 15px !important;
}
.phone-fix {
    display: inline-flex;
}
.drive-phone-input {
    display: flex;
    margin-bottom: 20px;
    align-items: baseline;
}
.drive-name-asterisk {
    margin-right: 9px;
    margin-left: 4px;
}
.drive-asterisk {
    color: red;
}
.rut-field-width {
    width: 100%;
}
.rut-field-margin {
    margin-bottom: 15px !important;
}
.total-width {
    width: 100% !important;
}
