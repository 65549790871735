.gray-bg {
    background-color: rgb(236, 234, 234);
}
.darkgray-bg {
    background-color: rgb(189, 189, 189);
}
.inspecciones-container {
    display: flex;

}
.inspecciones-title {
    flex: 1;
    margin: 0 !important;
    padding: 10px 5px;
}
.inspecciones-item {
    width: 100px;
    margin: 0 !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center;
}
.indicadores-form-title {
    margin: 0 !important;
    padding: 10px 5px;
}