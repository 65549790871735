:root {
	--primary-color: #1e88e5;
	--secondary-color: #4fc3f7;
}

#root {
	margin-bottom: 20px;
	@media screen and (min-width: 460px) {
		margin-bottom: 0;
	}
}

.relative {
	position: relative;
}

.header {
	&.navbar-default {
		background-color: var(--primary-color);
		.navbar-brand img {
			margin-right: 0.5rem;
		}
		.navbar-nav li > a .label {
			background-color: var(--secondary-color);
		}
	}
}

.ReactTable {
	.rt-table {
		.rt-thead {
			&.-header,
			.rt-th {
				border: none;
			}
		}
		.rt-tbody {
			.rt-td {
				border: none;
			}
		}
	}
}

.DataTable {
	font-size: 12px !important;
	padding: 15px;
}

.site-drawer-render-in-current-wrapper {
	position: relative;
	padding: 50px;
	overflow: hidden;
	background: #fafafa;
	border: 1px solid #ebedf0;
	border-radius: 2px;
}

.clickable {
	cursor: pointer;
}

.full {
	width: 100%;
}

.rdt_TableHeader {
	min-height: 0;
}

.is_advanced .rdt_Table .rdt_TableRow:first-child .rdt_TableCell {
	background-color: #f7f7f7;
}

.is_display_options .rdt_Table .rdt_TableRow:first-child .rdt_TableCell {
	padding-left: 2rem;
}

.input-group {
	display: flex;
	padding: var(--margin);
	flex-flow: row nowrap;
	width: 100%;
	border-radius: 4px;
	input[type="text"],
	input[type="number"] {
		background: none;
		outline: none;
		padding: 0.5rem;
		border: 1px solid #ddd;
		outline: none;
	}
	.btn {
		min-width: 40%;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}
}

.input-control {
	border: 0;
	padding: 0.5rem;
	background: none;
	outline: none;
	padding: 0;
	width: 100%;
	max-width: 250px;
	border-radius: 4px;
	border: 1px solid #ddd;
	&--masked {
		padding: 0;
		border: 0;
	}
}

.nav-item a {
	cursor: pointer;
}

.panel-right {
	width: 90%;
	max-width: 350px;
	height: 100%;
	max-height: calc(100vh - 50px);
	background-color: white;
	position: fixed;
	top: 50px;
	right: -350px;
	box-shadow: -3px 0 20px 0 rgba(0, 0, 0, 0.2);
	z-index: -10;
	display: none;
	&.active {
		animation: activate-right-panel 0.5s 0s forwards;
	}
	&.disable {
		animation: disable-right-panel 0.5s 0s forwards;
	}
	.panel-body {
		height: calc(100vh - 90px);
		max-height: calc(100vh - 90px);
		overflow-y: scroll;
		padding-bottom: 3rem;
	}
	&-dragger {
		width: 5px;
		cursor: ew-resize;
		padding: 4px 0 0;
		border-top: 1px solid #ddd;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		z-index: 100;
		background-color: #6f86a3;
	}
	&-toggle {
		display: flex;
		position: absolute;
		top: calc(30% - 50px);
		left: -2rem;
		width: 2rem;
		height: 2rem;
		background-color: white;
		font-size: 1.2rem;
		border-radius: 4px 0 0 4px;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		box-shadow: -5px 0 10px 0 rgba(0, 0, 0, 0.2);
		@media (min-width: 1024px) {
			// &-toggle {}
		}
	}
	@media screen and (min-width: 460px) {
		display: block;
		z-index: 100;
	}
}

.panel-accordion {
	width: 100%;
	&__group {
		width: 100%;
		&-title {
			padding: 0.5rem;
			font-weight: bold;
			color: #888;
			background-color: #f7f7f7;
			.fas,
			.far,
			.fal {
				margin-right: 0.5rem;
			}
			&.open {
				color: #222;
			}
		}
		&-items {
			display: none;
			width: 100%;
			&.open {
				display: block;
			}
		}
	}
	&__item {
		width: 100%;
		margin: 0;
		padding: 0.5rem;
	}
}

.full-height {
	height: 100%;
}

.finding-message {
	&::after {
		content: "";
		animation: ellip 1s 1s infinite;
	}
}

.modal {
	&--scrollable {
		.modal-body {
			max-height: 72vh;
			overflow-y: auto;
		}
	}
}

// Navbar
.navbar-white {
	background-color: #017794;
}

// Header
.header {
	a {
		color: white;
	}
	.navbar-nav > li > a .label {
		background: var(--secondary-color);
	}
	.operator-selector {
		.icon {
			color: white;
			margin-right: 6px;
		}
		display: flex;
		padding: 0 0.75rem;
		padding-right: 0;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-around;
		border-radius: 4px;
		border: 1px solid #ddd;

		select.form-control,
		select.form-control:focus {
			border-color: transparent;
			outline-color: transparent;
			outline-width: 0;
			color: white;
			background-color: transparent;
			option {
				background-color: transparent;
				color: black;
			}
		}
		i {
			font-size: 1rem;
		}
	}
	.dropdown {
		i {
			color: white;
		}
	}
	.navbar-user {
		span {
			color: white;
		}
	}
}

.login .login-v2 .brand {
	font-weight: bold;
}

// Sidebar
.sidebar {
	.nav > li.active > a i {
		color: var(--secondary-color);
	}
}

.logo-company {
	width: 40%;
	margin-left: 30px;
}

// Buttons
.btn {
	&-primary {
		background-color: var(--primary-color);
		color: white;
	}
}

.form-control:focus {
	border-color: inherit;
	-webkit-box-shadow: none;
	box-shadow: none;
}

@keyframes activate-right-panel {
	from {
		right: -350px;
	}
	to {
		right: -1px;
	}
}

@keyframes disable-right-panel {
	from {
		right: -1px;
	}
	to {
		right: -350px;
	}
}

@keyframes ellip {
	0%,
	25% {
		content: ".";
	}
	26%,
	50% {
		content: "..";
	}
	51%,
	75% {
		content: "...";
	}
	76%,
	100% {
		content: "";
	}
}
