.add-doc-btn {
    margin: 0 auto 15px auto;
    display: block !important;
}
.center-text {
    text-align: center;
    margin: 10px 0;
}
.mt-20 {
    margin-top: 20px;
}
.abs-center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.dynamic-field {
    display: flex;
    width: 90%;
    margin: 10px auto;
    justify-content: center;
}
.dynamic-field-item {
    width: 100%;
    max-width: 700px;
}
.dynamic-field-item .testresultfielditem {
    width: 50%;
}
.problem-category-field {
    width: 100%;
    display: flex;
    max-width: 700px;
}
.problem-category-field .ant-form-item {
    margin-bottom: 0;
    width: 100%;
}
.center-row {
    justify-content: center;
    margin-bottom: 15px;
}
.take-ticket {
   margin: 15px auto !important;
}
.center-row {
    justify-content: center;
}
.escalation-details-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
}
.escalation-buttonws-row-details {
    width: 300px;
    display: flex;
    justify-content: space-around;
}
.flex {
    display: flex;
}
.escalation-top-button {
    margin-right: 10px;
}
.escalation-typify-test-container {
    align-items: center;
}
.escalation-typify-test-name {
    margin-bottom: 0;
}
.escalation-typify-test-result {
    margin: 10px !important;
}

  
  
