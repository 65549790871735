.table-btn {
    font-size: .75rem !important;
    font-weight: 600 !important;
    border-radius: 4px !important;
    height: 34px !important; 
}
.table-btns {
    display: flex;
    justify-content: space-between;
}
.table-search-button {
    width: 200px !important;
}
.table-download-input {
    width: 225px !important;
    height: 34px !important;
    margin-right: 10px !important;
}
.table-align-right {
    margin-left: auto;
}
.table-pagination-fix {
    margin: 15px auto 5px auto !important;
    width: fit-content;
}
.table-pagination-fix .anticon-left {
    position: relative;
    bottom: 5px;
}
.table-pagination-fix .anticon-right {
    position: relative;
    bottom: 5px;
}