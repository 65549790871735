.removeEquipTemplate {
	display: flex;
	justify-content: center;
	width: 100%;
}

.removeEquipTemplateSelect {
	margin-top: 20px !important;
	margin-bottom: 20px !important;
	display: flex;
	justify-self: center;
	width: 100%;
}

.remove-equip-iframe {
	border: none;
	width: 100%;
	height: 500px;
}
.remove-equip-top-buttons {
    margin-bottom: 20px;
}
.remove-equip-title {
    font-size: 1.2rem;
}
