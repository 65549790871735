.meassurer {
	--width: 200px;
	--progress: 0;
	--color: #348fe2;
	--initial-dial: -90deg;
	display: table;
	position: relative;
	width: var(--width);
	height: calc(var(--width) / 2);
	margin: auto;
	.overflow {
		width: 100%;
		height: 100%;
		overflow: hidden;
		.bar {
			position: relative;
			width: 100%;
			height: 100%;
			border-radius: var(--width) var(--width) 0 0;
			background-color: #e0f3fc;
			// Progress bar
			&::before {
				content: "";
				width: 100%;
				height: 100%;
				border-radius: var(--width) var(--width) 0 0;
				position: absolute;
				background-color: var(--color);
				transform: rotate(calc(-180deg + (var(--progress) * (180deg / 100))));
				transform-origin: bottom center;
			}
			// White center
			&::after {
				content: "";
				width: calc(var(--width) / 1.5);
				height: calc(var(--width) / 3);
				border-radius: var(--width) var(--width) 0 0;
				position: absolute;
				top: 34%;
				left: 16%;
				background-color: white;
				transform-origin: bottom center;
				z-index: 2;
			}
		}
	}

	.dial {
		--size: 6px;
		position: absolute;
		top: calc(-1 * var(--size) + 3px);
		left: calc(50% - 3px);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 var(--size) calc(var(--width) * 0.5 + (var(--size) / 3)) var(--size);
		border-color: transparent transparent #666 transparent;
		border-radius: 0 0 10px 10px;
		z-index: 3;
		transform-origin: bottom center;
		transform: rotate(calc(var(--initial-dial) + ((180deg / 100) * var(--progress)) * (0.95 + (0.057 * var(--progress) / 100))));
	}

	._tooltip {
		--caret-size: .5rem;
		--background: rgba(0,0,0,0.75);
		position: fixed;
		z-index: 4;
		padding: calc(var(--caret-size) / 2);
		display: none;
		color: white;
		border-radius: calc(var(--caret-size) / 2);
		background-color: var(--background);
		&::before {
			content: "";
			position: absolute;
			bottom: calc(-1 * var(--caret-size));
			left: calc(50% - var(--caret-size));
			border-top: var(--caret-size) solid var(--background);
			border-left: var(--caret-size) solid transparent;
			border-right: var(--caret-size) solid transparent;
		}
	}

	&:hover {
		cursor: pointer;
		
		._tooltip {
			display: inline-block;
		}
	}
}