.steps-content {
	margin-top: 25px;
}
.wizard-result-icon {
	font-size: 100px;
	display: block;
	margin: 20px auto;
}
.wizard-iframe {
	border: none;
	width: 100%;
	height: 250px;
}
.wizard-btn {
	margin-bottom: 35px;
	width: 300px;
}
.wizard-row-item {
	margin-right: 25px !important;
}
.wizard-phone {
	position: relative;
	/* width: 15px !important; */
	bottom: 16px;
}
.wizard-phone-item .ant-form-item-control-input {
	display: none !important;
}
.wizard-phone-item .ant-form-item-explain {
	position: absolute;
	width: 180px;
	top: 30px;
}
.highlight-table {
    background-color: rgb(255, 225, 168);
}
.datepicker-wizard {
    width: 100%;
}
.wizard-form-title {
    font-size: 1.5rem;
    text-align: center;
}
.wizard-form-row {
    justify-content: space-around;
}
.wizard-due-day {
    position: relative;
    right: 15px;
    top: 5px;
}
.wizard-second-phone {
    display: inline-flex;
}
.wizard-second-fields-row {
    justify-content: space-between
}
.wizard-fix-width {
    width: 300px !important;
}